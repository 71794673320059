import React from 'react'
import { SideNav } from '../Navbar/Navbar'
// import SideNav  from '../Navbar/Navbar'
import Products from '../Products/Products'

// SideNav

const Collections = () => {
  return (
    <div>
      <Products />
    </div>
  )
}

export default Collections